import React, { useState, useEffect, useContext } from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import { motion } from "framer-motion";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import TextField from "@mui/material/TextField";
import { Swiper, SwiperSlide } from "swiper/react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { MdOutlineClose, MdShoppingBasket } from "react-icons/md";
import ModalPhoto from "../components/product/ModalPhoto";
import MetaTag from "../components/metaTag";
import {
  toggleButtonSx,
  toggleContainerSx,
  textFieldSx,
} from "../components/product/mui.style";
import {
  previewContainer,
  previewImage,
  image,
  settingsElement,
  additionalSettings,
  toggle,
} from "../style/pages/rubikscube.module.scss";
import {
  detail,
  settingsContainer,
  titleSettings,
  priceInfo,
  closeBtn,
  mobCloseBtn,
  costBundle,
  detailHeader,
  settings,
  settingsBlock,
  productInfo,
  settingsList,
  orderInfo,
  productContainer,
  productItem,
  mobileProducts,
  delProduct,
  hint,
  nubmer,
} from "../components/product/product.module.scss";

import { StoreContext } from "../context/store-context";

function Rubikscubestand() {
  const imageData = useStaticQuery(graphql`
    {
      allFile(
        filter: {
          relativeDirectory: { eq: "stand/rubikcube" }
          name: { nin: ["main"] }
        }
        sort: { order: ASC, fields: name }
      ) {
        nodes {
          childImageSharp {
            id
            gatsbyImageData(
              breakpoints: [750, 1366, 1920]
              formats: [AUTO, AVIF, WEBP]
              quality: 85
              placeholder: DOMINANT_COLOR
            )
          }
        }
      }
    }
  `);

  const [selectedImg, setSelectedImg] = useState(null);

  const { store, setStore } = useContext(StoreContext);
  const [totalPrice, setTotalPrice] = useState(0);

  const [material, setMaterial] = useState("");
  const [size, setSize] = useState("");
  const [cost, setCost] = useState(0);
  const [quantity, setQuantity] = useState(0);

  const [totalAmount, setTotalAmount] = useState(0);

  const [mobileList, setMobileList] = useState(false);

  const dataPlywood = [
    {
      size: "17х17 см, 2 яруса",
      cost: 600,
    },
    {
      size: "16х16см , 3яруса",
      cost: 700,
    },
    {
      size: "18х18 см, 3 яруса",
      cost: 800,
    },
    {
      size: "20х20см , 4яруса",
      cost: 1200,
    },
  ];

  // Обновить состояние для материала
  const handleMaterial = (event, newAlignment) => {
    setMaterial(newAlignment);
    if (newAlignment === "Акрил") {
      setSize("18х18см, 3 яруса");
      setCost(2000);
    } else {
      setSize("");
      setCost(0);
    }
    setQuantity(0);
  };

  // Обновить состояние для размера
  const handleSize = (event, newAlignment) => {
    setSize(newAlignment);
    if (newAlignment) {
      dataPlywood
        .filter((item) => item.size === newAlignment)
        .forEach((item) => setCost(item.cost));
    } else {
      setCost(0);
    }
  };

  // Обновить состояние для количества подложек
  const handleQuantity = (event) => {
    if (Number(event.target.value) >= 0) {
      setQuantity(Number(event.target.value));
    } else return;
  };

  // Добавить набор
  const addProduts = () => {
    const dataOrder = {
      material: material,
      size: size,
      quantity: quantity,
      totalAmount: totalAmount,
    };

    setTotalPrice(totalPrice + dataOrder.totalAmount);

    setStore({
      ...store,
      totalPrice: store.totalPrice + dataOrder.totalAmount,
      stand: {
        rubikcube: {
          bundle: [...store.stand.rubikcube.bundle, dataOrder],
          totalPrice: store.stand.rubikcube.totalPrice + dataOrder.totalAmount,
        },
        cake: { ...store.stand.cake },
        macarons: { ...store.stand.macarons },
      },
      acrylicfalsebeads: { ...store.acrylicfalsebeads },
      frame: { ...store.frame },
      toppers: { ...store.toppers },
      screens: { ...store.screens },
      spatulas: { ...store.spatulas },
      tag: { ...store.tag },
      sticks: { ...store.sticks },
      substrates: { ...store.substrates },
    });

    setSize("");
    setMaterial("");
    setCost(0);
    setQuantity(0);
    setTotalAmount(0);
  };

  // Удалить набор подложек
  const deleteProduct = (index) => {
    // Массив всех наборов
    const arrBundle = [...store.stand.rubikcube.bundle];
    // Цена набора, который нужно удалить
    const deductibleAmount = store.stand.rubikcube.bundle[index].totalAmount;

    // Удаляем из массива наборов элемент с заданным index
    arrBundle.splice(index, 1);
    setStore({
      ...store,
      totalPrice: store.totalPrice - deductibleAmount,
      stand: {
        rubikcube: {
          bundle: [...arrBundle],
          totalPrice: store.stand.rubikcube.totalPrice - deductibleAmount,
        },
        cake: { ...store.stand.cake },
        macarons: { ...store.stand.macarons },
      },
      acrylicfalsebeads: { ...store.acrylicfalsebeads },
      frame: { ...store.frame },
      toppers: { ...store.toppers },
      screens: { ...store.screens },
      spatulas: { ...store.spatulas },
      tag: { ...store.tag },
      sticks: { ...store.sticks },
      substrates: { ...store.substrates },
    });
  };

  useEffect(() => {
    let q = quantity || 0;
    let c = cost || 0;

    setTotalAmount(q * c);
  }, [quantity, cost]);

  return (
    <main className="mainpage">
      <MetaTag title="Подставки под Кубик Рубика | Wowmilota" />
      <motion.div
        layoutId="rubikscubeContainer"
        transition={{ type: "spring", bounce: 0.05, mass: 0.1 }}
        className={detail}
      >
        {selectedImg && (
          <ModalPhoto
            selectedImg={selectedImg}
            setSelectedImg={setSelectedImg}
          />
        )}

        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.4 }}
          className={previewContainer}
        >
          {imageData?.allFile?.nodes.map((img, index) => {
            const { childImageSharp } = img;
            const src = getImage(img);

            return (
              <motion.div
                onClick={() => setSelectedImg(src)}
                onKeyDown={() => setSelectedImg(src)}
                className={previewImage}
                key={childImageSharp.id}
                whileHover={{ scale: 0.995 }}
              >
                <GatsbyImage
                  image={src}
                  alt="Фотография подставок под кубик-рубика"
                  className={image}
                />
              </motion.div>
            );
          })}
        </motion.div>

        <div className={settingsContainer}>
          <div className={settings}>
            <div className={settingsBlock}>
              <div className={productInfo}>
                <div className={detailHeader}>
                  <h2>Подставки под Кубик Рубика</h2>
                </div>
                <p>
                  Подставка под торт-пирожные Кубик Рубика. Вращается вокруг
                  своей оси. Расстояние между ярусами 6 см, прекрасно подойдет
                  под пирожные 5см.
                </p>
              </div>

              <h3 className={titleSettings}>Обязательные параметры</h3>

              <div className={settingsList}>
                <div
                  className={settingsElement}
                  style={{
                    backgroundColor: material
                      ? "rgba(255, 255, 255, 0.15)"
                      : "rgba(255, 255, 255, 0.05)",
                  }}
                >
                  <h3>Материал</h3>
                  <ToggleButtonGroup
                    size="medium"
                    value={material}
                    exclusive
                    onChange={handleMaterial}
                    sx={toggleContainerSx}
                  >
                    <ToggleButton
                      value="Акрил"
                      key={`size1`}
                      sx={toggleButtonSx}
                    >
                      Акрил
                    </ToggleButton>
                    <ToggleButton
                      value="Фанера"
                      key={`size2`}
                      sx={toggleButtonSx}
                    >
                      Фанера
                    </ToggleButton>
                  </ToggleButtonGroup>
                </div>

                <div
                  className={settingsElement}
                  style={{
                    backgroundColor:
                      size || material === "Акрил"
                        ? "rgba(255, 255, 255, 0.15)"
                        : "rgba(255, 255, 255, 0.05)",
                  }}
                >
                  <h3>Размер (см)</h3>
                  {material === "Фанера" && (
                    <ToggleButtonGroup
                      size="medium"
                      value={size}
                      exclusive
                      onChange={handleSize}
                      sx={toggleContainerSx}
                    >
                      {dataPlywood.map((item) => {
                        const { size } = item;

                        return (
                          <ToggleButton
                            value={size}
                            key={`size-${size}`}
                            sx={toggleButtonSx}
                          >
                            {size}
                          </ToggleButton>
                        );
                      })}
                    </ToggleButtonGroup>
                  )}
                  {material === "Акрил" && <p>18х18см, 3 яруса</p>}
                  {!material && <p>Сначала выберите материал</p>}
                </div>

                <div
                  className={settingsElement}
                  style={{
                    backgroundColor:
                      quantity > 0
                        ? "rgba(255, 255, 255, 0.15)"
                        : "rgba(255, 255, 255, 0.05)",
                  }}
                >
                  <h3>Количество</h3>
                  <div className={toggle}>
                    <TextField
                      id="rubikscubeQuantity"
                      variant="standard"
                      type="number"
                      onFocus={(e) => e.target.value === "0" && setQuantity("")}
                      onChange={handleQuantity}
                      value={quantity}
                      sx={textFieldSx}
                      inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                    />
                  </div>
                </div>
              </div>

              <h3 className={titleSettings}>Характеристики</h3>

              <div className={additionalSettings}>
                <div
                  className={settingsElement}
                  style={{ backgroundColor: "rgba(255, 255, 255, 0.15)" }}
                >
                  <h3>Толщина</h3>
                  <p>Фанера: 6мм</p>
                  <p>Акрил: 5мм</p>
                </div>
                <div
                  className={settingsElement}
                  style={{ backgroundColor: "rgba(255, 255, 255, 0.15)" }}
                >
                  <h3>Высота между ярусами</h3>
                  <p>6 см</p>
                </div>
              </div>
            </div>

            <div className={orderInfo}>
              <h3>Ваши наборы</h3>
              {store?.stand.rubikcube.totalPrice > 0 ? (
                <div className={productContainer}>
                  {store?.stand.rubikcube.bundle.map((item, index) => {
                    return (
                      <div key={`order_${index}`} className={productItem}>
                        <button
                          className={delProduct}
                          aria-label="Удалить"
                          onClick={() => deleteProduct(index)}
                        >
                          <MdOutlineClose color="white" />
                        </button>
                        <h3>Набор {index + 1}</h3>
                        <p>Размер: {item.size}</p>
                        <p>Материал: {item.material}</p>
                        <p>{item.quantity} шт.</p>
                        <p>{item.totalAmount} руб.</p>
                      </div>
                    );
                  })}
                </div>
              ) : (
                <div className={hint}>
                  <p>
                    Пока у Вас нет наборов.
                    <br />
                    <br />
                    Когда Вы выберите все обязательные параметры, кнопка внизу
                    покажет стоимость набора.
                    <br /> <br />
                    Вы можете формировать любое количество наборов, просто
                    продолжайте выбирать параметры.
                  </p>
                </div>
              )}
            </div>

            <div className={priceInfo}>
              <div className={mobileProducts}>
                <button onClick={() => setMobileList(!mobileList)}>
                  <MdShoppingBasket color="white" />
                  <p className={nubmer}>
                    {store?.stand.rubikcube.bundle.length}
                  </p>
                </button>
                {mobileList && (
                  <Swiper
                    slidesPerView={"auto"}
                    spaceBetween={10}
                    grabCursor={true}
                    className={productContainer}
                  >
                    {store?.stand.rubikcube.bundle.map((item, index) => {
                      return (
                        <SwiperSlide
                          key={`order_${index}`}
                          className={productItem}
                        >
                          <button
                            className="delProduct"
                            aria-label="Удалить"
                            onClick={() => deleteProduct(index)}
                          >
                            <MdOutlineClose color="white" />
                          </button>
                          <h3>Набор {index + 1}</h3>
                          <p>Размер: {item.size}</p>
                          <p>Материал: {item.material}</p>
                          <p>{item.quantity} шт.</p>
                          <p>{item.totalAmount} руб.</p>
                        </SwiperSlide>
                      );
                    })}
                  </Swiper>
                )}
              </div>
              {totalAmount > 0 && material ? (
                <button className={costBundle} onClick={addProduts}>
                  {totalAmount > 0
                    ? `Добавить набор ${totalAmount} руб.`
                    : "Сформируйте набор"}
                </button>
              ) : (
                <p className={costBundle}>Сформируйте набор</p>
              )}
              <Link to="/" className={mobCloseBtn}>
                <MdOutlineClose color="white" />
              </Link>
              <Link to="/" className={closeBtn}>
                {store?.stand.rubikcube.bundle.length > 0
                  ? `Сохранить и закрыть`
                  : `закрыть`}
              </Link>
            </div>
          </div>
        </div>
      </motion.div>
    </main>
  );
}

export default Rubikscubestand;
